.arch-media-library {
    
    $sidebar-width: 500px;

    .crop-wrapper {
        overflow: scroll;
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;;
        background: rgba(0,0,0,.9);
        z-index: 999999999999;
    }

    .crop-container {
        width: 90%;
        max-width: 1280px;
        margin-left: auto;;
        margin-right: auto;;
    }

    .media-image {

        box-shadow: $box-shadow-sm;
        transition: all .3s;
        background-size: cover;
        background-position: center;

        @if $enable-rounded {
            border-radius: $border-radius;
        } @else {
            border-radius: 0;
        }

    }

    .media-img-preview {
        max-width: 100%;
    }

    #archk-media-library-image-container {
        max-height: 60vh;
        overflow: auto;
    }

   


    .media-sidebar {
        height: 100%;
        overflow: auto;
        transition: all .3s;
        position: fixed;
        right: - $sidebar-width;
        top: 0;
        bottom: 0;
        width:  $sidebar-width;
        background: white;
        z-index: 9999;
        box-shadow: $box-shadow-lg;

        padding: $card-spacer-y $card-spacer-x;


        .media-backdrop {
            right: 0; //setting this gives a fade in effect
        }

        &.show {
            right: 0;

            .media-backdrop {
                transition: all .3s;
                position: fixed;
                left: 0;
                top: 0;
                bottom: 0;
                right: $sidebar-width;

                background: rgba(0,0,0,.5)
            }

        }

    }
 
}