.alert {

    &.alert-danger {
        background: linear-gradient(to bottom right, $red, darken($red, 10%)) !important;
    }

    &.alert-info {
        background: linear-gradient(to bottom right, $teal, darken($teal, 10%)) !important;
    }

    &.alert-success {
        background: linear-gradient(to bottom right, $green, darken($green, 10%)) !important;
    }

    &.alert-warning {
        background: linear-gradient(to bottom right, $orange, darken($orange, 10%)) !important;
    }

}